import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  of,
  tap,
} from 'rxjs';
import { SelectOptions } from 'src/app/models/select.model';
import { ApiService } from 'src/app/shared/services/api.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ManagePPATimeseriesStore {
  private detailsSubject = new BehaviorSubject<any>(null);
  details$ = this.detailsSubject.asObservable();
  private endurDealTSSubject = new BehaviorSubject<any>(null);
  endurDealTSDetails$ = this.endurDealTSSubject.asObservable();
    
  constructor(
    private apiService: ApiService) {
  }

  setPPAPreviewDetails(details: any): void {
    this.detailsSubject.next(details);
  }

  getPPAPreviewDetails(): any {
    return this.detailsSubject.getValue();
  }
  setPPAEndurTSDetails(details: any): void {
    this.endurDealTSSubject.next(details);
  }

  getPPAEndurTSDetails(): any {
    return this.endurDealTSSubject.getValue();
  }
  private getOptions(options: string[]): SelectOptions {
    return options.map((option) => {
      return {
        label: option,
        value: option,
        disabled: false,
      };
    });
  }

  getPPPADealDetails(ppaDealId: any){
    return this.apiService.GetPPADealDetails(ppaDealId);
  }
}
